<script setup lang="ts">
  import { ref, onMounted, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useProjectsStore } from '@/stores/projects'
  import { useUserStore } from '@/stores/user'
  import { useModalStore } from '@/stores/modal'
  import { useCreatorStore } from '@/stores/creator'
  import DesLayout from '@/views/DesLayout.vue'
  import DesTile from '@/components/home/DesTile.vue'
  import DesTileDnD from '@/components/home/DesTileDnD.vue'
  import DesCarousel from '@/components/home/DesCarousel.vue'
  import DesCarouselItem from '@/components/home/DesCarouselItem.vue'
  import DesHomeBanner from '@/components/home/DesHomeBanner.vue'
  import DesHeading from '@/components/DesHeading.vue'
  import DesProjectList from '@/components/projects/DesProjectList.vue'
  import DesProject from '@/components/projects/DesProject.vue'
  import DesProjectContextMenu from '@/components/projects/DesProjectContextMenu.vue'
  import DesSearchWithResults from '@/components/DesSearchWithResults.vue'
  import DesBarActions from '@/components/bar/DesBarActions.vue'
  import { Products } from '@/helpers/product'
  import DesNoListElements from '@/components/DesNoListElements.vue'
  import { track } from '@/helpers/mixpanelDes'

  const projects = useProjectsStore()
  const user = useUserStore()
  const sharedList = ref<{ cols: number }>({ cols: 7 })
  const isLoading = ref<boolean>(true)
  const modal = useModalStore()
  const creator = useCreatorStore()

  onMounted(async () => {
    if (!user.isClientAccount()) {
      fetchRecentProjects()
    }

    const onboardingCompleted = await user.getExtraSettingByKey(user.userSettingLabels.ONBOARDING_COMPLETED)
    if (onboardingCompleted !== '1') {
      await modal.open<string>(() => import('@/components/modal/DesModalOnboarding.vue'))
    }
  })

  if (!user.isClientAccount()) {
    watch(
      () => sharedList.value.cols,
      (newVal, oldVal) => oldVal !== newVal && fetchRecentProjects(),
    )
  }

  const fetchRecentProjects = async () => {
    try {
      isLoading.value = true
      await projects.fetchRecentProjects(sharedList.value?.cols * 2)
    } finally {
      isLoading.value = false
    }
  }
</script>

<template>
  <DesLayout>
    <template #search>
      <DesSearchWithResults />
      <DesBarActions />
    </template>
    <template #menu></template>
    <template #main>
      <div class="flex flex-col gap-9 mb-48">
        <div class="grid grid-cols-3 gap-6">
          <DesTile
            @click="track('project-creating-type', { type: 'ebook' })"
            image="/dashboard/new-project/type-ebook.png"
            title="New eBook"
            desc="Start writing on a blank page or import"
            to="/creator/ebook"
          />
          <DesTile
            @click="track('project-creating-type', { type: 'audiobook' })"
            image="/dashboard/new-project/type-audiobook.png"
            title="New Audiobook"
            desc="Start writing on a blank page or import"
            to="/creator/audiobook"
          />
          <!--disabled for release-->
          <!--DesTileDnD to="/creator/canvas" />-->
        </div>

        <DesHomeBanner />

        <div>
          <DesHeading level="4" class="mb-6">More options to create an eBook</DesHeading>
          <DesCarousel>
            <DesCarouselItem
              @click="track('project-creating-source', { type: 'from-wordgenie' })"
              title="Generate with Wordgenie"
              image="/dashboard/new-project/new-wordgenie.png"
              to="/creator/wordgenie"
              :locked="!user.accountType.ai_credits"
            />
            <DesCarouselItem
              title="Import from Google Doc"
              image="/dashboard/new-project/new-gdoc.png"
              to="/creator/ebook/from-gdoc"
            />
            <DesCarouselItem
              title="PDF to Flipbook"
              image="/dashboard/new-project/new-flipbook.png"
              to="/creator/ebook/pdf-to-flipbook"
              @click="creator.setPublicationTypeToFlipbook()"
            />
            <DesCarouselItem
              title="Start from scratch"
              image="/dashboard/new-project/new-scratch.png"
              to="/docs/ebook/0"
            />
            <DesCarouselItem
              title="Import from Blog post or URL"
              image="/dashboard/new-project/new-blog.png"
              to="/creator/ebook/from-blog"
            />
            <DesCarouselItem
              title="Start from template"
              video="https://designrr.s3.amazonaws.com/videos/from-template.mp4"
              to="/templates"
            />
            <DesCarouselItem
              title="Import from DOCX"
              image="/dashboard/new-project/new-docx.png"
              to="/creator/ebook/from-docx"
            />
            <DesCarouselItem
              title="Import from PDF"
              image="/dashboard/new-project/new-pdf.png"
              to="/creator/ebook/from-pdf"
              :locked="!user.accountType.import_from_pdf"
            />
            <DesCarouselItem
              title="Import from video"
              video="https://designrr.s3.amazonaws.com/videos/from-video.mp4"
              to="/creator/ebook/from-video"
              :locked="!user.accountType.import_from_video"
              :unlockedFor="Products.Premium"
            />
            <DesCarouselItem
              title="Import from YouTube"
              video="https://designrr.s3.amazonaws.com/videos/from-youtube.mp4"
              to="/creator/ebook/youtube"
              :locked="!user.accountType.import_from_video"
              :unlockedFor="Products.Premium"
            />
            <DesCarouselItem
              title="Import from podcast"
              video="https://designrr.s3.amazonaws.com/videos/from-podcast.mp4"
              to="/creator/ebook/from-podcast"
              :locked="!user.accountType.import_from_podcast"
              :unlockedFor="Products.Premium"
            />
            <!-- <DesCarouselItem
              title="Merge docs"
              image="/dashboard/new-project/new-merge.png"
              to="/creator/ebook/merge-drafts"
              :locked="!user.accountType.merge_drafts"
            /> -->
          </DesCarousel>
        </div>

        <div>
          <DesHeading level="4" class="mb-6">Recent projects</DesHeading>

          <DesProjectList two-rows ref="sharedList">
            <DesProject v-for="project in projects.projects" :key="project.id" :item="project" disable-checkboxes />
          </DesProjectList>
        </div>

        <DesNoListElements
          v-if="!projects.projects.length && !isLoading"
          :itemName="'eBook'"
          :itemsName="'projects'"
          :newRoute="'/creator/ebook'"
        />
      </div>

      <DesProjectContextMenu />
    </template>
  </DesLayout>
</template>
